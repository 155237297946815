import React from 'react';
import Link from '@mui/material/Link';

import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';

import Typography from "@mui/material/Typography";

import Logo from "../images/cs_trade_logo_b.png";

export const NavigationBar = () => {


  return (
    <AppBar position="static" sx={{ background: '#2E3B55' }} >
        <Toolbar>
            <Link href="https://cs.trade">
            <Box
                component="img"
                sx={{
                height: 64,
                cursor:"pointer"
                }}
                alt="CS.TRADE"
                src={Logo}

            />
            </Link>
            <Box flexGrow={1} />
            <Link href="https://cs.trade/trade-csgo-skins" color='#ebebeb' underline="none">
                <Button variant="contained" style={{ color: '#ebebeb', backgroundColor: '#2E3B55' }}>
                    TRADE CS2 SKINS
                </Button>
            </Link>
        </Toolbar>
    </AppBar>
  );
};

