
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';

import Box from "@mui/material/Box";


export const Footer = () => {
    const style = {

      };
    return(
      <footer style={style}>
        <Box sx={{marginTop: '2px', borderTop: 1, borderColor: '#2E3B55'}} >
          <Grid container justifyContent="center" spacing={4} sx={{ height: 40}}>
            <Grid item>
              <Link href="https://support.cs.trade" color='#ebebeb' underline="hover" >Contact</Link>
            </Grid>
            <Grid item>
              <Link href="https://support.cs.trade" color='#ebebeb' underline="hover" >Support</Link>
            </Grid>
            <Grid item>
              <Link href="https://blog.cs.trade" color='#ebebeb' underline="hover">Blog</Link>
            </Grid>
          </Grid>
        </Box>
      </footer>

    );
};