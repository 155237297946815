import React from 'react';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const SkinPanel = (item) => {

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  function removeItem() {
    item.onRemove(item.item.inspect_url);
  }
  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

  return (
    <Paper
    sx={{
      p: 2,
      margin: 'auto',
      maxWidth: "100%",
      flexGrow: 1,
      m: 2,
      backgroundColor: "#2E3B55",
    }}
    >
      <Grid container sx={{margin: 'auto'}} spacing={2}   alignItems="center"
  justifyContent="center">
        <Grid item>
          <ButtonBase sx={{ width: 178, height: 148 }} onClick={() => openInNewTab(item.item.image_url)}>
            <Img alt="CS:GO Skin" src={item.item.thumbnail_url} onError={removeItem} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography color="#ebebeb" gutterBottom variant="subtitle1" component="div">
                {item.item.market_name}
              </Typography>
              <Typography color="#ebebeb" variant="body2" gutterBottom>
                Float: {item.item.float}
              </Typography>
              <Typography color="#ebebeb" variant="body2" >
                Pattern: {item.item.paintseed}
              </Typography>
            </Grid>
              <Grid item>
                <Stack direction="row" spacing={1}>
                  <Tooltip title="Copy image link">
                  <IconButton aria-label="copy image link" onClick={() => navigator.clipboard.writeText(item.item.image_url)}>
                    <LinkIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Inspect in-game">
                  <IconButton aria-label="inspect" color="primary" onClick={() => openInNewTab(item.item.inspect_url)}>
                    <SearchIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Trade">
                  <IconButton color="primary" aria-label="trade on cs.trade" onClick={() => openInNewTab("https://cs.trade")}>
                    <AddShoppingCartIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove">
                  <IconButton color="secondary" aria-label="remove" onClick={removeItem}>
                    <DeleteIcon />
                  </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

