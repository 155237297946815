import axios from "axios";

const API_URL = "https://ss.cs.trade/";

const getScreenshot = (inspect_url) => { 
  return axios.get(API_URL + "getScreenshot?url="+inspect_url);
};


export default {
    getScreenshot
};